import React from 'react';

import JeongImage from '../../assets/images/donald_uncut.png';
import Back from '../../utils/Back';
import OpenSea from '../icons/OpenSea';
import YoutubeEmbed from '../MINSunghong/YT';
import Social from '../Social/Social';

import threeImege from '../../assets/mobile/three.png';
import roomwith from '../../assets/videos/a room with moving light_03.mp4';
import metaph from '../../assets/videos/metaphysical star_03.mp4';
function Jeong() {
  return (
    <div>
      <div className='relative md:h-screen'>
        <div className='no-scroll fixed top-7 left-7 z-40 max-h-screen overflow-y-scroll  scroll-smooth  opacity-90 '>
          <Back />
        </div>

        <img
          src={JeongImage}
          className='mx-auto hidden h-screen w-screen object-cover md:block md:h-screen'
          alt='page jeng'
        />

        <img
          src={threeImege}
          className='mx-auto block h-screen w-screen object-cover md:hidden md:h-screen'
          alt='page jeng'
        />

        <p className='absolute top-1/2 left-1/2  w-full -translate-x-1/2 -translate-y-1/2 transform text-center text-4xl font-bold text-white md:block md:text-7xl'>
          JEONG, Jeong-Ju
        </p>
      </div>
      <div className='mx-auto mb-24 mt-7 max-w-6xl text-center  md:mb-44 md:mt-32'>
        <div className='py-5 text-4xl font-bold'>JEONG, Jeong-Ju</div>
        <p className='px-5 text-justify md:text-center'>
          Jeongju Jeong majored in sculpture at Hongik University and acquired
          the title of Meisterschueler under Professor Hubert Kiecol at the
          Kunstakademie Duesseldorf in Germany in 2002. The artist presents
          various types of installation works inspired by architectural space
          and places. Space and light, in particular, play an important role in
          his works due to his impression of the sharp light that poured into a
          narrow window at his rented room during his study in Germany. The
          light penetrating through the sturdy surface of a European-style
          building was like the gaze at relationships with strangers in a
          foreign land and reaffirmation of his presence. Since then, the artist
          has continued formative installations by creating models of buildings
          and adding light to them. His works overturn the power of gaze and
          reconstruct psychological relationships. He has held 15 solo
          exhibitions in Seoul, Japan, China and Belgium since 2002. ‘future is
          now’ (National Museum of Contemporary Art, Gwacheon), ‘Thermocline of
          Art’ (ZKM, Germany) and many other domestic and international
          exhibitions. In 2010, he won the ‘Kim Chong Young Art Award’, the 2003
          ‘Gwangju Shinsegae Art Award’, and participated in the Geumcheon Art
          Factory in 2009, the National Studio of Goyang Art in 2006, and the
          Ssamzie Space Residency in 2003. He is currently a Professor at
          Sungshin University.
        </p>
      </div>
      {/* dada */}
      <div className='text-center'>
        <div className='mx-auto  mt-4 flex  flex-col justify-center space-y-16 md:mt-10 md:space-y-24'>
          <div className='flex flex-col items-center justify-center'>
            <p className='w-56 py-10 text-center text-3xl font-bold md:w-full'>
              a room with moving light 1
            </p>
            <video width='620' height='240' autoplay muted={true} controls>
              <source src={roomwith} type='video/mp4' />
            </video>
            <OpenSea link='https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/28207103087853850150566911069871071797533150488488548368327139267545550290949' />
          </div>
          <div className='flex flex-col items-center justify-center'>
            <p className='w-56 py-10 text-center text-3xl font-bold md:w-full'>
              metaphysical star22-01
            </p>
            <video width='620' height='240' autoplay muted={true} controls>
              <source src={metaph} type='video/mp4' />
            </video>{' '}
            <OpenSea
              link={
                'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/28207103087853850150566911069871071797533150488488548368327139268645061918725'
              }
            />
          </div>
          <div className='flex flex-col items-center justify-center pb-24'>
            <YoutubeEmbed embedId='C8Q3Xp794NY' />
          </div>
        </div>
        <Social />
      </div>
    </div>
  );
}

export default Jeong;
