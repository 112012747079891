import React from 'react';

function Menu() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='12'
      viewBox='0 0 25 12'
      fill='#000'
    >
      <line
        x1='1'
        y1='1'
        x2='24'
        y2='1'
        stroke='white'
        strokeWidth='2'
        stroke-linecap='round'
      />
      <line
        x1='1'
        y1='6'
        x2='24'
        y2='6'
        stroke='white'
        strokeWidth='2'
        stroke-linecap='round'
      />
      <line
        x1='1'
        y1='11'
        x2='24'
        y2='11'
        stroke='white'
        strokeWidth='2'
        stroke-linecap='round'
      />
    </svg>
  );
}

export default Menu;
