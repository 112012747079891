import React from 'react';

function Valores() {
  return (
    <svg
      width='175'
      height='15'
      viewBox='0 0 175 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M127.813 0V5.64599H149.644V0H127.813ZM114.996 5.41606C116.356 5.41606 117.464 5.95255 117.464 6.64234L117.489 6.82117C117.489 7.51095 116.381 8.07299 115.047 8.07299H107.921V5.41606H114.996ZM36.4856 8.14963L38.2734 11.7518L34.723 11.7263L36.4856 8.14963ZM127.813 7.66423V13.3102H149.644V7.66423H127.813ZM91.8813 6.28467C92.9389 6.28467 93.795 7.12774 93.8202 8.22628V12.7993C93.795 13.8467 92.964 14.7409 91.9065 14.7409H84.4532C83.3957 14.7153 82.5396 13.8467 82.5396 12.7993V8.22628C82.5396 7.15329 83.3957 6.28467 84.4532 6.28467H91.8813ZM0 0L10.3237 21H19.2122L29.536 0H21.0755L19.4892 3.24453L16.518 9.24818L14.7554 12.8504L12.9676 9.24818L10.0468 3.24453L8.43525 0H0ZM32.054 0L21.7302 21H30.1655L31.7518 17.7555H41.1942L42.7806 21H51.2158L40.9424 0H32.054ZM53.205 0V21H75.3633V14.0255H60.7338L60.6079 0H53.205ZM93.4425 0H93.4173H82.9424C79.4676 0.0255474 76.6475 2.88686 76.6475 6.38686V14.6131C76.6475 18.1131 79.4676 21 82.9424 21H93.4173C96.8921 21 99.7374 18.1387 99.7374 14.6131V6.38686C99.7122 2.86131 96.8921 0 93.4425 0ZM100.921 0V21H107.871V13.9489H115.576L119.126 21H126.277L122.047 12.5182C123.306 11.3942 124.036 9.75912 124.036 8.04745V5.90146C124.036 2.65693 121.417 0 118.219 0H100.921ZM127.813 15.3285V21H149.644V15.3285H127.813ZM155.435 0C153.471 0 151.91 1.60949 151.91 3.57664C151.885 3.67883 151.885 3.80657 151.91 3.93431V5.31387C151.91 8.20073 154.025 9.27372 157.047 10.5255L165.935 13.8212H151.86V17.3978C151.86 19.3905 153.446 21 155.385 21H171.424C173.388 21 174.975 19.3905 174.975 17.3978V15.7628C174.975 12.8759 172.86 11.8029 169.813 10.5511L160.698 7.17883H175V3.57664C175 1.58394 173.414 0 171.475 0H155.435Z'
        fill='url(#paint0_linear_477_162)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_477_162'
          x1='40.6358'
          y1='-41.2269'
          x2='139.898'
          y2='56.6073'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#279678' />
          <stop offset='0.36' stopColor='#2AAF8B' />
          <stop offset='0.53' stopColor='#2DC79D' />
          <stop offset='0.67' stopColor='#2AAF8B' />
          <stop offset='1' stopColor='#279678' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Valores;
