import React from 'react';
import { motion } from 'framer-motion';

import Back from '../../utils/Back';
import A from '../../assets/images/details/artist1.png';
import Social from '../Social/Social';
import Logo from '../icons/Logo';
function ArtistDetails() {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
        exit={{ opacity: 0, delay: 0.5 }}
        className=' '
      >
        <div className='flex  w-full flex-col  items-center justify-center  bg-white  '>
          <div className='fixed top-5 left-5'>
            <Back />
          </div>

          <img
            src={A}
            className='  h-screen justify-center object-cover object-bottom  md:mt-36 md:h-[710px] md:w-[500px]'
            alt='artist'
          />

          <div className=' korean-word flex items-center justify-center py-10 pb-4  font-bold md:text-3xl lg:text-5xl'>
            <Logo />
          </div>
          <div className='korean-word  py-3 px-5 text-center font-bold tracking-wider md:text-sm lg:text-2xl'>
            {/* <Trans i18nKey='leeeun.guidLinesDetails' /> */}
            Gallery Chosun NFT Collection information for KIAF PLUS 2022
            {/* {t('leeeun.guidLinesDetails')} */}
          </div>
          <div className='px-5'>
            <div className='korean-word md:text-sm lg:text-base'>
              <p className='max-w-4xl text-justify text-[16px] md:text-center'>
                Gallery Chosun is a premium art gallery located in Bukchon,
                South Korea alongside other galleries and the National Museum of
                Modern and Contemporary Art. With its tall ceiling height and
                customizable walls, the gallery has earned a reputation to have
                a unique space for every exhibition. Gallery Chosun keeps itself
                up-to-date with new trends across the world and tries to promote
                the artists who can be recognized in art history, which is the
                gallery’s mission and pride. Recently, a media archive of its
                artists has also been created.
              </p>
            </div>
          </div>
        </div>
        {/* second */}
      </motion.div>
      <div className='mt-20'>
        <Social />
      </div>
    </>
  );
}

export default ArtistDetails;
