import React from 'react';
import SanghoonImg from '../../assets/images/shivering.png';
import OpenSea from '../icons/OpenSea';
import two from './two.png';
import three from './three.png';
import Social from '../Social/Social';
import YoutubeEmbed from '../MINSunghong/YT';

import twoImage from '../../assets/mobile/two.png';
import afterVideo from '../../assets/videos/AFTERPAINTING1.mp4';
import Back from '../../utils/Back';

function Sanghoon() {
  return (
    <div>
      <div className='relative md:h-screen'>
        <div className='no-scroll fixed top-7 left-7  max-h-screen w-full  	 overflow-y-scroll scroll-smooth opacity-90 '>
          <Back />
        </div>
        <img
          src={SanghoonImg}
          className='mx-auto hidden h-screen w-screen object-cover md:block md:h-screen'
          alt='Sanghoon img'
        />

        <img
          src={twoImage}
          className='mx-auto h-screen w-screen object-cover md:hidden md:h-screen'
          alt='Sanghoon img'
        />
        <div className=' absolute   top-1/2 left-1/2   w-full -translate-x-1/2 -translate-y-1/2 transform text-center text-4xl font-bold  text-white md:text-7xl'>
          Sanghoon AHN
        </div>
      </div>
      <div className='mx-auto mb-24 mt-7 max-w-6xl text-center md:mb-44 md:mt-32'>
        <div className='py-5 text-4xl font-bold'>Sanghoon AHN</div>
        <p className='px-5 text-justify md:text-center'>
          AHN Sanghoon majored in Painting at Jung-Ang University, received his
          Akademiebrief and was a Meisterschueler at Kunstakademie Muenster. The
          artist constantly raises meaningless questions what painting is to him
          and experiments the nature of painting expanding into space. He held
          solo exhibitions at Incheon Art Platform (2018, Incheon), Kreis
          Museum(2016, Osterburg, Germany). He participated in various group
          shows including exhibitions at Oil Tank Culture Park, Soorim Cultural
          Foundation, Gyeonggi Creation Center, Plan B Project Space, Incheon
          Art Platform, Tempelhof Museum, Kunsthalle Muenster in Germany and
          many more. He has participated in several artist residency programs
          including Kunsthof Dahrenstedt, Incheon Art Platform, Gyeonggi
          Creation Center and will be working as an artist in MMCA Residency
          Goyang in 2020.
        </p>
      </div>
      {/* dada */}
      <div className='text-center'>
        <div className='mx-auto mt-10 flex flex-col  justify-center space-y-24'>
          <div className='flex flex-col items-center justify-center'>
            <div className='py-10 text-2xl font-bold'>
              AFTERPAINTING 1
              <div className='text-sm'>(Good painting, 2017)</div>
            </div>

            <video width='620' height='240' autoplay muted={true} controls>
              <source src={afterVideo} type='video/mp4' />
            </video>

            <OpenSea link='https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/28207103087853850150566911069871071797533150488488548368327139264247015407626' />
          </div>
          <div className='flex flex-col items-center justify-center'>
            {' '}
            <div className='py-10 text-2xl font-bold'>
              AFTERPAINTING 2
              <div className='text-sm'>(Good painting, 2017)</div>
            </div>
            <img src={two} alt='one  2' width='620px' />
            <OpenSea link='https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/28207103087853850150566911069871071797533150488488548368327139265346527035402' />
          </div>
          <div className='flex flex-col items-center justify-center'>
            <div className='py-10 text-2xl font-bold'>
              AFTERPAINTING 3{' '}
              <div className='text-sm'>(Good painting, 2017)</div>
            </div>
            <img src={three} alt='one  3' width='620px' />
            <OpenSea link='https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/28207103087853850150566911069871071797533150488488548368327139266446038663178' />
          </div>

          <div className='flex flex-col items-center justify-center pb-24'>
            <YoutubeEmbed embedId='3Uyq4Mhm0T4' />
          </div>
          <div className='flex flex-col items-center justify-center pb-24'>
            <YoutubeEmbed embedId='SYGW6GzpWuI' />
          </div>
          <div>{/* <YoutubeEmbed embedId='y8Sx5tmO4PA' /> */}</div>
        </div>
        <Social />
      </div>
    </div>
  );
}

export default Sanghoon;
