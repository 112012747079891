import React from 'react';
import { motion } from 'framer-motion';
function Content({ title, desc, size }) {
  return (
    <div className='flex flex-col'>
      <motion.div
        className='items-start  text-5xl font-bold text-white lg:text-6xl'
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2, delay: 0.5 }}
      >
        {title}
      </motion.div>
    </div>
  );
}

export default Content;
