import React from 'react';

function Button({ text, pos }) {
  return (
    <button
      className={
        'md:text-1xl cursor-pointer rounded-full border-2 border-[#2DC79D] bg-white py-1.5 px-3 text-sm font-bold text-[#2DC79D] shadow-2xl ' +
        [pos ? ' absolute bottom-10 right-10 z-50' : '']
      }
    >
      {text}
    </button>
  );
}

export default Button;
