import React from 'react';

function Logo() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 127.2 22.1'
      className='h-12 items-center text-center'
    >
      <defs></defs>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            class='cls-1'
            d='M81.6 9a3.3 3.3 0 0 0-1.4-.3c-1.4 0-2.2 1-2.2 2.6s.7 2.7 2.2 2.7a4.8 4.8 0 0 0 1.7-.4l.1 1.8a7 7 0 0 1-2.1.3c-2.8 0-4.1-1.9-4.1-4.4s1.4-4.3 4-4.3a7.3 7.3 0 0 1 2 .3ZM83.7 4.5h2.1v3.8A3 3 0 0 1 88.4 7c2 0 2.8 1.4 2.8 3.2v5.3h-2.1V11c0-1 0-2.4-1.4-2.4-1.6 0-1.9 1.7-1.9 2.7v4h-2.1ZM97.2 7a4.1 4.1 0 0 1 4.4 4.4 4.3 4.3 0 0 1-8.7 0A4.1 4.1 0 0 1 97.2 7Zm0 7c1.7 0 2.1-1.5 2.1-2.9a2.1 2.1 0 1 0-4.1 0c0 1.4.4 2.9 2 2.9ZM108.4 9a5.4 5.4 0 0 0-2-.3c-.5 0-1.2.2-1.2.9 0 1.3 3.8.5 3.8 3.4 0 2-1.8 2.7-3.5 2.7a10.1 10.1 0 0 1-2.5-.3l.2-1.8a4.7 4.7 0 0 0 2.1.6c.6 0 1.4-.2 1.4-1 0-1.7-3.7-.6-3.7-3.5 0-1.9 1.5-2.7 3.2-2.7a10.2 10.2 0 0 1 2.4.3ZM118 15.5h-2v-1.1a3.5 3.5 0 0 1-2.7 1.3c-2 0-2.8-1.4-2.8-3.3V7.2h2.1v4.4c0 1 0 2.4 1.4 2.4 1.6 0 2-1.7 2-2.7v-4h2ZM119.6 7.2h2v1.1a3 3 0 0 1 2.8-1.3c2 0 2.8 1.4 2.8 3.2v5.3h-2.1V11c0-1 0-2.4-1.4-2.4-1.6 0-2 1.7-2 2.7v4h-2Z'
            fill='#58B0E3'
          />
          <path
            class='cls-2'
            d='M26 13.7c0 2.1-1 3.3-3.5 3.3a7 7 0 0 1-2.5-.5l.1-1.2a5.1 5.1 0 0 0 2.5.7 2 2 0 0 0 2.1-2.4V13a2.8 2.8 0 0 1-2.3 1c-2 0-2.9-1.6-2.9-3.4s1-3.6 3-3.6a2.3 2.3 0 0 1 2.3 1.2v-1H26Zm-1.3-3c0-1.4-.6-2.6-2-2.6s-1.9 1.4-1.9 2.5.8 2.4 2 2.4a2.1 2.1 0 0 0 2-2.3ZM29.5 8a5.3 5.3 0 0 1 2.7-.8c2.4 0 3.3 1.1 3.3 3.4V14a13.8 13.8 0 0 0 0 1.6h-1.4v-1a3 3 0 0 1-2.5 1.2c-1.8 0-3-.8-3-2.6 0-2 2.3-2.7 3.8-2.7H34c0-1.4-.5-2.1-2-2.1a3.9 3.9 0 0 0-2.4.8Zm4.5 3.7h-1c-.8 0-2.9 0-2.9 1.5a1.4 1.4 0 0 0 1.6 1.4 2 2 0 0 0 2.3-2.2ZM38.4 4.8h1.5v10.8h-1.5ZM43.4 4.8H45v10.8h-1.5ZM54.6 15.3a6.5 6.5 0 0 1-2.6.5c-3 0-4.3-1.8-4.3-4.5 0-2.4 1.6-4.1 3.9-4.1 2.7 0 3.6 2 3.6 4.6h-5.9a2.7 2.7 0 0 0 2.8 2.8 5 5 0 0 0 2.5-.9Zm-1-4.6a2 2 0 0 0-2-2.3 2.3 2.3 0 0 0-2.2 2.3ZM57.8 7.3h1.5v1.3a2.5 2.5 0 0 1 2.1-1.4 4 4 0 0 1 1 0v1.4a2.3 2.3 0 0 0-.8-.1c-1.4 0-2.3 1.3-2.3 3.3v3.8h-1.5ZM64.8 17.7a2.4 2.4 0 0 0 .7.1c1.3 0 1.8-1.9 1.8-2a4.9 4.9 0 0 0-.3-.9l-2.8-7.7h1.7L68 14l2.3-6.8h1.5l-3 8.4c-.6 1.6-1.2 3.4-3.2 3.4a5 5 0 0 1-1-.1Z'
            fill='#ABADB0'
          />
          <path
            class='cls-1'
            d='M0 .6v21.1c0 .3.4.4.7.3l12.5-3.6c.4 0 .6-.3.6-.5V5a.3.3 0 0 0-.2-.3L.9 0A.6.6 0 0 0 .7 0a.6.6 0 0 0-.7.6Zm1.1.5 11.6 4.3v12.4L1 21.1v-20Z'
            fill='#58B0E3'
          />
        </g>
      </g>
    </svg>
  );
}

export default Logo;
