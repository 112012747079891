import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
function Back() {
  const navigate = useNavigate();
  return (
    <motion.div
      onClick={() => navigate(-1)}
      initial={{ y: -200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ y: -200, opacity: 0 }}
      className='flex w-fit items-center rounded-full border-[2px] border-green-500 bg-white px-4'
    >
      <motion.div
        initial={{ rotate: 180 }}
        animate={{ rotate: 0 }}
        transition={{ duration: 0.9 }}
        exit={{ rotate: 180 }}
      >
        <motion.svg
          width='20'
          height='33'
          viewBox='0 0 35 33'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.47471 14.3125H32.8125C33.3927 14.3125 33.9491 14.5429 34.3593 14.9532C34.7696 15.3634 35 15.9198 35 16.5C35 17.0801 34.7696 17.6365 34.3593 18.0468C33.9491 18.457 33.3927 18.6875 32.8125 18.6875H7.47471L17.5175 28.7303C17.9283 29.1407 18.1592 29.6976 18.1594 30.2783C18.1596 30.8589 17.9291 31.4159 17.5186 31.8267C17.1082 32.2374 16.5513 32.4683 15.9706 32.4685C15.39 32.4687 14.833 32.2382 14.4222 31.8278L0.640959 18.0465C0.230866 17.6363 0.000488281 17.08 0.000488281 16.5C0.000488281 15.9199 0.230866 15.3636 0.640959 14.9534L14.4222 1.17216C14.8333 0.761694 15.3905 0.531329 15.9714 0.531739C16.5523 0.532149 17.1092 0.763302 17.5197 1.17435C17.9302 1.58539 18.1605 2.14265 18.1601 2.72355C18.1597 3.30444 17.9286 3.86138 17.5175 4.27185L7.47471 14.3125Z'
            fill='#2DC79D
            '
          />
        </motion.svg>
      </motion.div>
    </motion.div>
  );
}

export default Back;
