import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageContent from '../ImageContent';
import Button from '../../utils/Button';
// import Content from '../Content';

// non active images
import artist_nonactive from '../../assets/images/notactive/main.png';
import morning_nonactive from '../../assets/images/notactive/morning.png';
import shivering_nonactive from '../../assets/images/notactive/shivering.png';
import d_non from '../../assets/images/notactive/donald.png';
// active
import artist from '../../assets/images/mainimage.png';
import morning from '../../assets/images/morning.png';
import shivering from '../../assets/images/shivering.png';
// image have transparent bg to fit on larger screen
import { useTranslation } from 'react-i18next';
import Content from '../Content';
// import Moralis from 'moralis';
// image container have a children

function Home() {
  const [active, setActive] = useState(0);
  const [slideSelected, setSlideSelected] = useState(false);
  const { t, i18n } = useTranslation();

  const {
    translator: { language },
  } = i18n;

  const handleSlideChange = (index) => {
    if (!index) {
      setSlideSelected(false);
    } else {
      setSlideSelected(true);
    }
    setActive(index);
  };

  // const handleLangChange = () => {
  //   if (language === 'ko') {
  //     i18n.changeLanguage('en');
  //   } else {
  //     i18n.changeLanguage('ko');
  //   }
  // };

  console.log(t);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className=' no-scroll flex h-screen flex-col justify-between overflow-y-auto   bg-gradient-to-r from-slate-500 to-yellow-100 md:flex-row  '
    >
      {/* close icons  */}

      {/* {form ? <WinnerForm t={t} setMiss={setMiss} setForm={setForm} /> : ''} */}

      {closeIcons(slideSelected, handleSlideChange)}
      {/* active slides  */}
      <ImageContent
        active={active}
        handleSlideChange={handleSlideChange}
        activeImageClass='main'
        nonActiveImageClass='main_nonactive'
        nonActiveImage={artist_nonactive}
        activeImage={artist}
        activeState={1}
      >
        <div
          className={
            'flex h-screen w-full flex-col  items-center justify-center  pb-10   md:mx-10 md:items-start ' +
            [language === 'en' ? 'mt-2 pt-10' : 'pt-10 ']
          }
        ></div>
        <div
          className={
            ' absolute left-5 bottom-52 z-50  md:bottom-20 ' +
            [active === 1 ? '' : 'w-40 ']
          }
        >
          <Content title='Gallery Chosun' />
        </div>
        <Link to='/gallery-chosun'>
          <Button text='See details' pos={true} />
        </Link>
      </ImageContent>
      <ImageContent
        active={active}
        handleSlideChange={handleSlideChange}
        nonActiveImage={morning_nonactive}
        activeImage={morning}
        activeState={2}
      >
        <div className='flex h-screen w-full items-center justify-center shadow-xl  md:hidden '>
          {/* <img src={morning} className=' w-4/5  object-cover' alt='morning' /> */}
        </div>
        <div
          className={
            ' absolute left-5 bottom-32 z-50  md:bottom-20 ' +
            [active === 2 ? '' : 'w-40 ']
          }
        >
          <Content title='MIN Sung Hong' />
        </div>
        <Link to='/min-sunghong'>
          <Button text='See details' pos={true} />
        </Link>
      </ImageContent>
      {/* three */}
      <ImageContent
        active={active}
        handleSlideChange={handleSlideChange}
        nonActiveImage={shivering_nonactive}
        activeImage={shivering}
        activeState={3}
      >
        <div className='flex h-screen w-full items-center justify-center shadow-xl  md:hidden '>
          {/* <img src={shivering} className=' w-4/5  object-cover' alt='donald' /> */}
        </div>
        <div className=' absolute right-5 bottom-52 z-50 md:bottom-20'>
          {/* <Content
          //     title='Shivering'
          //     desc='2022, acrylic, oil, spray on cotton'
          //     size='112.1x145.5cm'
          //   /> */}
        </div>
        <div
          className={
            ' absolute left-5 bottom-32 z-50 md:bottom-20 ' +
            [active === 3 ? '' : 'w-40 ']
          }
        >
          <Content title='Sang hoon AHN' />
        </div>
        <Link to='/sanghoon-ahn'>
          <Button text='See details' pos={true} />
        </Link>
      </ImageContent>

      <ImageContent
        active={active}
        handleSlideChange={handleSlideChange}
        nonActiveImage={d_non}
        activeImage={d_non}
        activeState={5}
      >
        <div className='flex h-screen w-full items-center justify-center shadow-xl  md:hidden '>
          {/* <img src={shivering} className=' w-4/5  object-cover' alt='donald' /> */}
        </div>
        <div
          className={
            ' absolute left-5 bottom-32 z-50 md:bottom-20 ' +
            [active === 5 ? '' : 'w-56 ']
          }
        >
          <Content title='Jeong, Jeong-Ju' />
        </div>
        <Link to='/jeong'>
          <Button text='See details' pos={true} />
        </Link>
      </ImageContent>
      {/* four */}
    </motion.div>
  );
}

// close icon: set selected diagram to 0
const closeIcons = (slideSelected, handleSlideChange) => {
  return (
    <AnimatePresence>
      {slideSelected && (
        <motion.div
          initial={{ x: -100, opacity: 0, rotation: 0 }}
          animate={{ x: 0, opacity: 1, rotate: 180 }}
          transition={{ duration: 0.5 }}
          exit={{ x: -100, opacity: 0, rotation: 0 }}
          className='absolute top-10 right-10 z-40 hidden h-14 w-14 cursor-pointer items-center justify-center  rounded-full border-[1px] text-black md:flex  '
          onClick={() => handleSlideChange()}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-11 w-11'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth='2'
          >
            <motion.path
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 0.7, ease: 'easeInOut' }}
              exit={{ pathLength: 0 }}
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

// const languageButton = (handleLangChange, language) => {
//   return (
//     <button
//       type='button'
//       onClick={() => handleLangChange()}
//       className={
//         'absolute top-5 left-4 z-50 my-2 mx-5 self-start bg-white px-2 py-1 text-left  text-xl lg:left-10 lg:mx-0 '
//       }
//     >
//       {language === 'ko' ? 'EN' : 'KO'}
//     </button>
//   );
// };

export default Home;
