import React from 'react';
import { motion } from 'framer-motion';
function ImageContent({
  active,
  handleSlideChange,
  activeState,
  nonActiveImage,
  activeImage,
  children,
}) {
  return (
    <>
      <motion.div
        whileTap={{ scale: 0.99 }}
        className={
          // if its active in mobile mode set hight to screen height else set screen 1/2
          // if its active in desktop set width to 800vw else let flex decide
          [
            active === activeState
              ? `active  md:w-[800vw] `
              : `ideal  h-screen  `,
          ] + `   relative  w-full md:flex`
        }
        onClick={() => handleSlideChange(activeState)}
      >
        <div>
          <img
            src={active === activeState ? activeImage : nonActiveImage}
            alt=' absolute'
            className={
              ' absolute h-screen  w-screen  object-cover brightness-50  ' +
              [active === activeState ? ' z-10  ' : 'gray']
            }
          />
        </div>
        <div className='relative  z-20 h-screen w-full overflow-hidden'>
          {children}
        </div>
        {/* <AnimatePresence>
        {!(active === activeState) && (
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ x: -100, opacity: 0, rotation: 0 }}
            className='absolute bottom-1 left-5 z-20 h-full'
          >
            as
          </motion.div>
        )}
      </AnimatePresence> */}
      </motion.div>
      {/* {active === 1 ? (
        <div
          className={`absolute h-screen w-screen bg-slate-300 md:hidden ${nonActiveImageClass} `}
        >
          asar
        </div>
      ) : active === 2 ? (
        '2'
      ) : active === 3 ? (
        '2'
      ) : active === 4 ? (
        '4'
      ) : (
        ''
      )} */}
    </>
  );
}

export default ImageContent;
