import React from 'react';

const YoutubeEmbed = ({ embedId }) => (
  <div className='video-responsive'>
    <iframe
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      title='Embedded youtube'
      className='h-60 w-96 md:h-60 md:w-96 lg:h-[400px] lg:w-[700px]'
    />
  </div>
);

export default YoutubeEmbed;
