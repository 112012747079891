import React from 'react';
import MinSungImage from '../../assets/images/morning.png';
import one from './one.png';
import two from './two.png';
import three from './three.png';
import OpenSea from '../icons/OpenSea';
import YoutubeEmbed from './YT';
import Back from '../../utils/Back';
import Social from '../Social/Social';

import oneImage from '../../assets/mobile/one.png';

function MinSung() {
  return (
    <div>
      <div className='relative md:h-screen'>
        <div className='no-scroll fixed top-7 left-7  max-h-screen w-full  	 overflow-y-scroll scroll-smooth opacity-90 '>
          <Back />
        </div>

        <img
          src={MinSungImage}
          className='mx-auto hidden h-screen w-screen object-cover md:block md:h-screen'
          alt='mini sung  img'
        />
        <img
          src={oneImage}
          className='mx-auto block h-screen w-screen object-cover md:hidden md:h-screen'
          alt='mini sung  img'
        />
        <p className='absolute top-1/2 left-1/2  w-full -translate-x-1/2 -translate-y-1/2 transform text-center text-4xl font-bold text-white  md:text-7xl '>
          MIN SungHong
        </p>
      </div>
      <div className='mx-auto mb-24 mt-7 max-w-6xl text-center md:mb-44 md:mt-32'>
        <div className='py-5 text-4xl font-bold'>MIN SungHong </div>
        <p className='px-5 text-justify md:text-center'>
          SungHong Min’s Work is a spatial installation that visualizes the
          conditions of contemporary people who now face greater conflicts and
          concerns as a result of external stimuli and changes. The installation
          also shows the process of finding solutions amid such struggle. To
          humans, everyday life acts as a priority and an element of restriction
          at the time. In addressing this, Min uses objects that were lost or
          left behind due to unjust systems in the course of involuntary
          migration in order to discuss invisible mutual relationships and
          identities. These abandoned objects are defined by the artist as
          objects that have lost their functions and memories attached to them.
          Through his work, SungHong Min communicates the idea that even the
          restrictions of reality which instigate inner conflict are valued
          aspects of life.
        </p>
      </div>
      {/* dada */}
      <div className='text-center'>
        <div className='mx-auto mt-10 flex flex-col  justify-center space-y-24'>
          <div className='flex flex-col items-center justify-center'>
            <p className='w-40 py-10 text-2xl font-bold md:w-full'>
              Exercise for Sensitivity 01
            </p>
            <img
              src={one}
              alt='one  1'
              className='w-[350px] object-cover px-10 md:w-[520px] md:px-0'
            />
            <OpenSea
              link={
                'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/28207103087853850150566911069871071797533150488488548368327139260948480524293'
              }
            />
          </div>
          <div className='flex flex-col items-center justify-center'>
            <p className='w-40 py-10 text-2xl font-bold md:w-full'>
              Exercise for Sensitivity 02
            </p>{' '}
            <img
              src={two}
              alt='one  2'
              className='w-[350px] object-cover px-10 md:w-[520px] md:px-0'
            />
            <OpenSea
              link={
                'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/28207103087853850150566911069871071797533150488488548368327139262047992152069'
              }
            />
          </div>
          <div className='flex flex-col items-center justify-center'>
            <p className='w-40 py-10 text-2xl font-bold md:w-full'>
              Exercise for Sensitivity 03
            </p>
            <img
              src={three}
              alt='one  3'
              className='w-[350px] object-cover px-10 md:w-[520px] md:px-0'
            />
            <OpenSea
              link={
                'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/28207103087853850150566911069871071797533150488488548368327139263147503779845'
              }
            />
          </div>
          <div className='flex flex-col items-center justify-center pb-24'>
            <YoutubeEmbed embedId='y8Sx5tmO4PA' />
          </div>
        </div>
      </div>
      <Social />
    </div>
  );
}

export default MinSung;
