import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ArtistDetails from './components/ArtistDetails/ArtistDetails';
import Home from './components/Home/Home';
// import Mint from './components/Mint/Index';
import { AnimatePresence } from 'framer-motion';
import './i18n';
import MinSung from './components/MINSunghong';
import Sanghoon from './components/SanghoonAHN';
import Jeong from './components/Jeong';

function App() {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path='/' element={<Home />} />
        <Route path='/gallery-chosun' element={<ArtistDetails />} />
        <Route path='/min-sunghong' element={<MinSung />} />
        <Route path='/sanghoon-ahn' element={<Sanghoon />} />
        <Route path='/jeong' element={<Jeong />} />

        {/* <Route path='/mint' element={<Mint />} /> */}
      </Routes>
    </AnimatePresence>
  );
}

export default App;
