import React, { useState } from 'react';
import Menu from '../icons/Menu';
import Close from './CloseIcon';

function MobileMenu() {
  const [menu, setMenu] = useState(false);
  const { pathname } = window.location;
  console.log(pathname);

  const handleNavigation = (path) => {
    window.location.pathname = path;
  };
  return (
    <div className='md:hidden'>
      <div
        className={
          'items-between absolute top-0 z-50 flex  w-full flex-col space-y-8 ' +
          [menu ? 'bg-white' : '']
        }
      >
        <div
          className='absolute top-10 right-10 z-20'
          onClick={() => setMenu(true)}
        >
          <Menu />
        </div>
        {menu ? (
          <div className='bg-white'>
            <div className='relative z-50 mx-10 flex justify-between'>
              <div className='text-2xl font-bold uppercase'>gallery chosun</div>
              <div onClick={() => setMenu(false)}>
                <Close />
              </div>
            </div>
            <div className='mt-8 space-y-7 py-10 text-center font-bold'>
              <div
                className={[
                  pathname === '/gallery-chosun' ? 'text-green-500' : '',
                ]}
                onClick={() => handleNavigation('/gallery-chosun')}
              >
                About Gallery Chosun
              </div>
              <div
                className={[
                  pathname === '/min-sunghong' ? 'text-green-500' : '',
                ]}
                onClick={() => handleNavigation('/min-sunghong')}
              >
                MIN SungHong
              </div>
              <div
                className={[
                  pathname === '/sanghoon-ahn' ? 'text-green-500' : '',
                ]}
                onClick={() => handleNavigation('/sanghoon-ahn')}
              >
                Sanghoon AHN
              </div>
              <div
                className={[pathname === '/jeong' ? 'text-green-500' : '']}
                onClick={() => handleNavigation('/jeong')}
              >
                JEONG, Jeong-Ju
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default MobileMenu;
